export default function compare() {
    const hasLevels = $("input[name='single-salary-levels']").val();

    $('[data-level-switcher-item]').click(function (event) {
        const target = $(this).attr('data-level-switcher-item');

        $(`[data-level-switcher-item]`).removeClass('active');
        $(this).addClass('active');

        $('[data-level]').removeClass('active');
        $(`[data-level="${target}"]`).addClass('active');

        const overallCopy = $('[data-single-salary-copy-overall]');
        overallCopy.hide();

        const levelsCopies = $('[data-single-salary-copy-level]');
        levelsCopies.hide();

        $(`[data-single-salary-copy-level="${target}"]`).show();
    });

    $('[data-void-switcher-item]').click(function (event) {
        const target = $(this).attr('data-void-switcher-item');

        $(`[data-void-switcher-item]`).removeClass('active');
        $(this).addClass('active');

        $('[data-voidvoideship]').removeClass('active');
        $(`[data-voidvoideship="${target}"]`).addClass('active');
    });

    $('[data-company-switcher-item]').click(function (event) {
        const target = $(this).attr('data-company-switcher-item');

        $(`[data-company-switcher-item]`).removeClass('active');
        $(this).addClass('active');

        $('[data-company]').removeClass('active');
        $(`[data-company="${target}"]`).addClass('active');
    });

    initToggles();
    initLevelsText();

    function initLevelsText() {
        if (!hasLevels) return;

        const overallCopy = $('[data-single-salary-copy-overall]');
        overallCopy.hide();

        const levelsCopies = $('[data-single-salary-copy-level]');
        levelsCopies.hide();

        // $(`[data-single-salary-copy-level="starszy-specjalista"]`).first().show();
        if (levelsCopies.length === 1) {
            levelsCopies.first().show();
        } else {
            $(`[data-single-salary-copy-level="doswiadczony-specjalista"]`).first().show();
        }
    }

    function initToggles() {
        const specialistLevel = $('[data-level-switcher-item="specjalista"]');

        if (specialistLevel[0]) {
            $(`[data-level-switcher-item]`).removeClass('active');

            specialistLevel.each(function () {
                $(this).addClass('active');
                $(this).trigger('click');
            });
        }
    }
}